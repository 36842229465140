<template>
    <el-form ref="form" v-if="loadForm" :rules="rules" :model="person" class="card-tail" v-loading="$waiting.is('updating')">
        <p v-if="!addPerson" class="border-b mb-4 pb-2 font-medium"><i class="pr-1 fa-light fa-user" /> {{ person.name }}</p>
        <p v-else class="border-b mb-4 pb-2 font-medium"><i class="pr-1 fa-light fa-user-pen" />Skapa ny</p>
        <div class="flex">
            <el-form-item label="Namn" class="w-1/2 pr-2" prop="name">
                <el-input v-model="person.name" />
            </el-form-item>
            <el-form-item label="Personnummer" class="w-1/2 pl-2" prop="civicNumber">
                <el-input v-model="person.civicNumber" placeholder="ÅÅÅÅMMDD-XXXX">
                    <template slot="append">ÅÅÅÅMMDD-XXXX</template>
                </el-input>
            </el-form-item>
        </div>
        <div class="flex">
            <el-form-item label="Adress" class="w-1/2 pr-2">
                <el-input v-model="person.address" />
            </el-form-item>
            <el-form-item label="Adress2" class="w-1/2 pl-2">
                <el-input v-model="person.address2" />
            </el-form-item>
        </div>
        <div class="flex">
            <el-form-item label="Postnummer" class="w-1/2 pr-2" prop="postalCode">
                <el-input v-model="person.postalCode" />
            </el-form-item>
            <el-form-item label="Ort" class="w-1/2 pl-2">
                <el-input v-model="person.city" />
            </el-form-item>
        </div>
        <div class="flex">
            <el-form-item label="Land" class="w-1/2 pr-2" prop="countryId">
                <el-select class="w-full" v-model="person.countryId">
                    <el-option v-for="country in countryOptions" :label="country.name" :value="country.value" :key="country.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="Epost" class="w-1/2 pl-2" prop="email">
                <el-input v-model="person.email" />
            </el-form-item>
        </div>
        <el-form-item class="w-1/2 pr-2">
            <el-checkbox label="Medlem i kyrkan" name="type" v-model="churchTaxChkbox" @change="person.churchTax = churchTaxChkbox" />
        </el-form-item>
        <div class="flex">
            <el-form-item label="Kommun" class="w-1/2 pr-2">
                <el-select filterable class="w-full" v-model="person.municipalName" placeholder="--- Välj kommun ---" @change="selectParishOptions">
                    <el-option v-for="municipal in municipalOptions" :label="municipal.name" :value="municipal.value" :key="municipal.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="Församling" class="w-1/2 pl-2" v-if="person.churchTax">
                <el-select class="w-full" v-model="person.parishName" placeholder="--- Välj församling ---">
                    <el-option v-for="parish in parishOptions" :label="parish.name" :value="parish.value" :key="parish.value" />
                </el-select>
            </el-form-item>
        </div>
        <div class="flex">
            <el-form-item label="Bank privat" class="w-1/2 pr-2">
                <el-input v-model="person.bankName" />
            </el-form-item>
            <el-form-item label="Bankkonto privat (inkl clearing)" class="w-1/2 pl-2" prop="bankAccountNumber">
                <el-input v-model="person.bankAccountNumber" />
            </el-form-item>
        </div>
        <div class="flex">
            <el-form-item label="Ocr-nummer hos Skv" class="w-1/2 pr-2">
                <el-input v-model="person.ocrSkv" />
            </el-form-item>
            <el-form-item label="Ägarandel %" class="w-1/2 pl-2" prop="ownerShare">
                <InputCurrency v-model="person.ownerShare" :custom-class="inputBorder" />
            </el-form-item>
        </div>
        <div class="flex">
            <el-form-item label="Identitetshandling finns" class="w-1/2 pr-2">
                <el-date-picker type="date" placeholder="Pick a date" style="width: 100%;" v-model="person.gotIdentificationCard" />
            </el-form-item>
            <el-form-item label="Annan inkomst från huvudarbetsgivare" class="w-1/2 pl-2">
                <InputCurrency v-model="person.otherAnnualIncome" :custom-class="inputBorder" />
            </el-form-item>
        </div>
        <el-form-item>
            <el-checkbox label="Huvudman" v-model="ownerChkbox" @change="person.principal = ownerChkbox" />
            <el-checkbox label="Inaktivera login" v-model="disableLoginChkbox" @change="person.disableLogin = disableLoginChkbox" />
            <el-checkbox label="Har inlogg" v-model="person.hasLogin" disabled />
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="onSubmit">Spara</el-button>
            <el-button type="primary" @click="onSubmitAndCreateLogin">Spara och skapa login</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import Api from "../clients.api";
import moment from "moment";

export default {
    props: {
        personId: {
            type: Number,
            default: 0,
        },

        addPerson: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        async personId() {
            this.$nextTick(() => {
                this.$refs.form.clearValidate();
            });
            this.resetCheckBoxes();
            if (!this.addPerson) {
                this.$waiting.start("updating");
                await this.getPersonDetails(this.personId);
                this.$waiting.end("updating");
            } else {
                this.resetForm();
            }
        },
    },

    components: {
        InputCurrency: () => import(/* webpackChunkName: "InputCurrency" */ "@/components/InputCurrency.vue"),
    },

    data() {
        return {
            person: {},
            municipalOptions: [],
            parishParentOptions: [],
            parishOptions: [],
            countryOptions: [],
            ownerChkbox: false,
            disableLoginChkbox: false,
            churchTaxChkbox: false,
            rules: {
                name: [
                    { required: true, message: "Namn saknas", trigger: "blur" },
                    { max: 50, message: "Längden får inte vara mer än 50", trigger: "blur" },
                ],
                email: [
                    { required: true, message: "Epost saknas", trigger: "blur" },
                    { type: "email", message: "Vänligen ange korrekt e-postadress", trigger: "blur" },
                ],
                civicNumber: [
                    { required: true, message: "Personnummer saknas", trigger: "blur" },
                    { pattern: /(^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])-\d{4}$)/, message: "Ange ett korrekt personnummer 12 siffror ÅÅÅÅMMDD-XXXX", trigger: "blur" },
                ],
                postalCode: [{ max: 16, message: "Längden får inte vara mer än 16", trigger: "blur" }],
                countryId: [{ max: 2, message: "Ange ID med 2 tecken", trigger: "blur" }],
            },
        };
    },

    async created() {
        var options = await Api.getMunicipalOptions();
        this.countryOptions = await Api.getCountryList();
        this.municipalOptions = options.municipals;
        this.parishParentOptions = options.parishes;
    },

    methods: {
        resetCheckBoxes() {
            this.ownerChkbox = false;
            this.disableLoginChkbox = false;
            this.churchTaxChkbox = false;
        },

        resetForm() {
            this.$refs.form.resetFields();
            this.person = {};
            this.person.countryId = "SE";
        },

        assignCheckBoxes() {
            this.ownerChkbox = this.person.principal;
            this.disableLoginChkbox = this.person.disableLogin;
            this.churchTaxChkbox = this.person.churchTax;
        },

        selectParishOptions() {
            this.person.parishName = "";
            this.parishOptions = this.parishParentOptions.filter(p => p.name == this.person.municipalName)[0].parishes;
        },

        async getPersonDetails() {
            this.$waiting.start("updating");
            this.person = await Api.getPersonDetails(this.personId);
            this.parishOptions = this.person.parishes;
            this.assignCheckBoxes();
            this.$waiting.end("updating");
        },

        async onSubmit() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$waiting.start("updating");
            this.createObjForSaving();
            let isSuccess = await Api.updatePerson({ clientId: this.$route.params.clientId, dto: this.person, id: this.personId });
            if (isSuccess) {
                this.$emit("reloadList");
            }
            this.$notify.success({ title: "Utfört" });
            this.$waiting.end("updating");
        },

        async onSubmitAndCreateLogin() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$waiting.start("updating");
            this.createObjForSaving();
            let isSuccess = await Api.saveAndCreateLogin({ clientId: this.$route.params.clientId, dto: this.person, id: this.personId });
            if (isSuccess) {
                this.$emit("reloadList");
            }
            this.$notify.success({ title: "Utfört" });
            this.$waiting.end("updating");
        },

        createObjForSaving() {
            if (this.person.gotIdentificationCard != null) this.person.gotIdentificationCard = moment(this.person.gotIdentificationCard).format("YYYY-MM-DD HH:mm:ss");
            if (this.person.ownerShare == null || this.person.ownerShare == "" || this.person.ownerShare == undefined) this.person.ownerShare = 0;
            if (this.person.otherAnnualIncome === null || this.person.otherAnnualIncome === undefined || this.person.otherAnnualIncome === "") this.person.otherAnnualIncome = 0;
        },
    },

    computed: {
        loadForm() {
            if (this.personId !== 0 || this.addPerson) {
                return true;
            } else return false;
        },

        inputBorder() {
            return "w-full text-sm py-1 px-2 rounded border border-solid input-border";
        },
    },
};
</script>
